<template>
    <div>
        <h1 class="text-2xl font-medium mb-4"> Add a Manager </h1>
        <div class="flex flex-col space-y-4 w-full sm:w-1/2 lg:w-4/12">
            <div>
                <label> is KAM (Key Account Manager) </label>
                <vs-switch v-model="isKAM" />
            </div>
            <div>
                <label> Select A User </label>
                <vs-select autocomplete v-model="user" v-validate="'required'" data-vv-as="visible to"
                    data-vv-scope="info" name="user" placeholder="Select a user" val-icon-success="done"
                    val-icon-danger="error" :danger="errors.first('user')" :danger-text="errors.first('user')"
                    :success="!errors.first('user')" class="w-full">
                    <vs-select-item v-for="(item, index) in users" :key="index" :value="item" :text="item.firstName" />
                </vs-select>
            </div>
            <div>
                <label> Account Mananger Name </label>
                <vs-input :success="!errors.first('name')" :danger="errors.first('name') ? true : false"
                    :danger-text="errors.first('name')" data-vv-scope="info" val-icon-success="done"
                    val-icon-danger="error" data-vv-as="name" name="name" class="w-full" v-model="name"
                    v-validate="'required'" />
            </div>
            <div>
                <label> Phone Number </label>
                <vs-input :success="!errors.first('phone')" :danger="errors.first('phone') ? true : false"
                    :danger-text="errors.first('phone')" data-vv-scope="info" val-icon-success="done"
                    val-icon-danger="error" data-vv-as="phone" name="phone" class="w-full" v-model="phone"
                    v-validate="'required'" />
            </div>
            <div>
                <label> Email </label>
                <vs-input :success="!errors.first('email')" :danger="errors.first('email') ? true : false"
                    :danger-text="errors.first('email')" data-vv-scope="info" val-icon-success="done"
                    val-icon-danger="error" data-vv-as="email" name="email" class="w-full" v-model="email"
                    v-validate="'required'" />
            </div>
        </div>
        <vs-button :disabled="!validateForm" type="filled" @click="save" class="px-6 mt-2">Add Manager</vs-button>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: 'AddAccountManager',
    components: {},
    data() {
        return {
            name: '',
            isKAM: false,
            phone: null,
            email: null,
            user: null,
            users: [],
        };
    },
    computed: {
        validateForm() {
            return !this.errors.any();
        },
    },
    methods: {
        ...mapActions("admin", ["fetchAllAdmins"]),
        ...mapActions("appUser", ["saveAccountManagerDetails"]),

        async getUsers() {
            this.fetchAllAdmins().then(res => {
                this.users = res.data.data
            })
        },

        async save() {
            let isValid = await this.$validator.validateAll("info");

            if (isValid && this.user) {
                const data = {
                    userId: this.user._id,
                    name: this.name,
                    isKAM: this.isKAM,
                    phone: this.phone,
                    email: this.email,
                }
                this.$vs.loading();
                this.saveAccountManagerDetails(data)
                    .then((result) => {
                        if(result.message === "Success") {
                            this.$vs.notify({
                                title: "Success",
                                text: "Account manager created successfully.",
                                color: "success",
                            });
                            this.$router.back()
                        } else {
                            this.$vs.notify({
                                title: "Error",
                                text: result.message,
                                color: "danger",
                            });
                        }
                    })
                    .catch((err) => {
                        this.$vs.notify({
                            title: "Error",
                            text: err.data.message,
                            color: "danger",
                        });
                    })
                    .finally(() => {
                        this.$vs.loading.close();
                    })
        }},
    },
    watch: {
        "user": function (newVal, oldVal) {
            if (newVal !== "") {
                this.email = newVal.email,
                    this.name = newVal.firstName + " " + newVal.lastName
            }
        },
    },
    mounted() {
        this.getUsers();
    }
}
</script>